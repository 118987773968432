// export const baseurl = "https://py-banktoken.mobiloitte.io";
export const baseurl = "https://betapy.fineximbank.com/"; // ---------------- beta live
// export const baseurl = "http://172.16.6.141:8000";
export const reCaptchaKey = "6LfUy9UpAAAAAOem85goMJaUQYtdegN3jD9Ih-Z6";

const url = `${baseurl}/api/v1/admin`;

const ApiConfig = {
  login: `${url}/login/`,
  myProfile: `${url}/my-profile/`,
  forgotPassword: `${url}/forgot-password/`,
  verifyOtp: `${url}/forgot-password-verify-otp/`,
  resendOtp: `${url}/forgot-password-resend-otp/`,
  resetPassword: `${url}/reset-password/`,
  walletAddress: `${url}/wallet-address`,

  editProfile: `${url}/my-profile/edit`,
  changePassword: `${url}/my-profile/change-password`,

  //dashboard
  dashboardList: `${url}/dashboard`,
  allocateFunds: `${url}/reward-management/allocate-funds/`,
  distributeFunds: `${url}/reward-management/distribute-funds/`,

  //subAdmin
  subAdminList: `${url}/sub-admin-management/sub-admin-list/`,
  editSubAdmin: `${url}/sub-admin-management/sub-admin-update`,
  viewSubAdmin: `${url}/sub-admin-management/sub-admin-detail`,
  addSubAdmin: `${url}/sub-admin-management/sub-admin-create/`,
  subAdminXlsData: `${url}/sub-admin-management/sub-admin-list-xls/`,
  blockUnblockSubAdmin: `${url}/sub-admin-management/sub-admin-block-unblock`,
  deleteUser: `${url}/sub-admin-management/sub-admin-delete`,

  //userManagement
  userResetGoogleAuthenticator: `${url}/user-management/user-reset-google-authenticator`, ///user-reset-google-authenticator
  userManagementList: `${url}/user-management/user-list/`,
  userDetail: `${url}/user-management/user-detail/`,
  blockUnblockUser: `${url}/user-management/user-block-unblock`,

  //kycManagement
  kycManagementList: `${url}/kyc-management/`,
  KycXlsData: `${url}/kyc-management-xls/`,
  approveKyc: `${url}/kyc-management/aprove-kyc/`,
  rejectKyc: `${url}/kyc-management/reject-kyc`,

  //ticketManagement
  ticketManagementList: `${url}/ticket-management/ticket-list/`,
  ticketReply: `${url}/ticket-management/ticket-reply`,
  ticketXlsData: `${url}/ticket-management/ticket-list-xls/`,

  walletManagementList: `${url}/wallet-management/list`,
  walletXlsData: `${url}/wallet-management/list-xls`,
  updateWallet: `${url}/wallet-management/update-wallet/`,
  withdraw: `${url}/wallet-management/withdraw/`,
  deposit: `${url}/wallet-management/deposit/`,

  //transaction
  transactionManagementList: `${url}/transaction-management/list`,
  transactionXlsData: `${url}/transaction-management/list-xls`,

  //reward managemment
  rewardManagementList: `${url}/reward-management/list`,
  rewardXlsData: `${url}/reward-management/list-xls`,
  rewardManagementSetting: `${url}/reward-management/settings`,
  editRewardData: `${url}/reward-management/settings`,

  //feeManagement
  feeManagementData: `${url}/fee-management`,
  updateFeeManagement: `${url}/fee-management`,

  //static content
  getPrivacyPolicyContent: `${url}/static-content-management/privacy-policy/`,
  getAboutUsContent: `${url}/static-content-management/about-us/`,
  editAboutUsContent: `${url}/static-content-management/about-us/`,
  getTermsContent: `${url}/static-content-management/tearms-and-conditions/`,
  editPrivacyPolicyContent: `${url}/static-content-management/privacy-policy/`,
  editTermsAndConditionContent: `${url}/static-content-management/tearms-and-conditions/`,
  getCookiesContent: `${url}/static-content-management/cookie-policy/`,
  editCookiesContent: `${url}/static-content-management/cookie-policy/`,

  //faqManagement
  faqManagementList: `${url}/static-content-management/faqs/`,
  addFAQ: `${url}/static-content-management/faqs/`,
  editFAQ: `${url}/static-content-management/faqs`,
  deleteFAQ: `${url}/static-content-management/faqs`,

  //bannerManagement
  bannerManagementList: `${url}/static-content-management/banner/`,
  addBanner: `${url}/static-content-management/banner/`,

  //teamManagement
  teamList: `${url}/static-content-management/team-member-list/`,
  addteamMember: `${url}/static-content-management/team-member-create/`,
  viewTeamMember: `${url}/static-content-management/team-member`,
  editTeamMember: `${url}/static-content-management/team-member`,
  deleteTeamMember: `${url}/static-content-management/team-member`,

  //blogManagement
  blogList: `${url}/static-content-management/blogs/`,
  addBlog: `${url}/static-content-management/blogs/`,
  viewBlog: `${url}/static-content-management/blogs`,
  editBlog: `${url}/static-content-management/blogs`,
  deleteBlog: `${url}/static-content-management/blogs`,
  blockUnblockBlog: `${url}/static-content-management/blogs`,

  //bank overview
  bankDetailsList: `${url}/bank-overview/bank-details`,
  editBankDetails: `${url}/bank-overview/bank-details`,
};
export default ApiConfig;
