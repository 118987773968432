import axios from "axios";
// import { ApiConfig } from "../index";
import toast from "react-hot-toast";
import { encryptJSION } from "src/utils/crypto";
import ApiConfig from "../ApiConfig";

export const apiRouterCall = async ({
  method,
  url,
  bodyData,
  paramsData,
  token,
}) => {
  try {
    return await axios({
      method: method,
      url: url,
      headers: {
        Authorization: token ? token : window.sessionStorage.getItem("token"),
      },
      data: bodyData ? { payload: encryptJSION(bodyData).toString() } : null,
      params: paramsData
        ? { payload: encryptJSION(paramsData).toString() }
        : null,
    });
  } catch (error) {
    if (error.response) {
      return error?.response;
    } else {
      toast.error(error.message);
      return;
    }
  }
};
export const apiRouterCallNew = async ({
  method,
  url,
  bodyData,
  paramsData,
}) => {
  try {
    return await axios({
      method: method,
      url: url,
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
      data: bodyData ? { payload: encryptJSION(bodyData).toString() } : null,
      params: paramsData
        ? { payload: encryptJSION(paramsData).toString() }
        : null,
    });
  } catch (error) {
    if (error.response) {
      return error?.response;
    } else {
      toast.error(error.message);
      return;
    }
  }
};
export const dataPostHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
      data: dataToSend
        ? { payload: encryptJSION(dataToSend).toString() }
        : null,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(" error ", error);
    if (error.response) {
      if (error.response.status === 440) {
        window.location.href = "/";
      }
      if (error.response.status === 403) {
        // window.localStorage.removeItem("token");
        // window.location.href = "/";
        window.localStorage.removeItem("access_token");
      }
      return error?.response;
    }
  }
};

export const postAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
  id,
  dataToSendWEnc,
  profile,
  images,
}) => {
  try {
    // console.log(" ---------------- dataToSend ", id);
    return await axios({
      method: "POST",
      url: id ? `${ApiConfig[endPoint]}/${id}/` : ApiConfig[endPoint],
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
      data: dataToSend
        ? {
            payload: encryptJSION(dataToSend).toString(),
            updateProfile: profile ? profile : null,
            image: images ? images : null,
          }
        : null,
      params: paramsData
        ? { payload: encryptJSION(paramsData).toString() }
        : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const putAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
  id,
  images,
}) => {
  try {
    return await axios({
      method: "PUT",
      url: id ? `${ApiConfig[endPoint]}/${id}/` : ApiConfig[endPoint],
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
      data: dataToSend
        ? {
            payload: encryptJSION(dataToSend).toString(),
            image: images ? images : null,
          }
        : null,
      params: paramsData
        ? { payload: encryptJSION(paramsData).toString() }
        : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
  id,
}) => {
  try {
    return await axios({
      method: "DELETE",
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
      data: dataToSend
        ? { payload: encryptJSION(dataToSend).toString() }
        : null,
      params: paramsData
        ? { payload: encryptJSION(paramsData).toString() }
        : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAPIHandler = async ({ endPoint, id, source, paramsData }) => {
  try {
    return await axios({
      method: "GET",
      url: id ? `${ApiConfig[endPoint]}/${id}/` : ApiConfig[endPoint],
      params: paramsData
        ? { payload: encryptJSION(paramsData).toString() }
        : null,
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
  }
};
export const patchAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
  id,
  images,
}) => {
  try {
    return await axios({
      method: "PATCH",
      url: id ? `${ApiConfig[endPoint]}/${id}/` : ApiConfig[endPoint],
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
      data: dataToSend
        ? {
            payload: encryptJSION(dataToSend).toString(),
            image: images ? images : undefined,
          }
        : null,
      params: paramsData
        ? { payload: encryptJSION(paramsData).toString() }
        : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getDataHandlerAPI = async ({
  endPoint,
  dataToSend,
  paramsData,
}) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
      },
      data: dataToSend
        ? { payload: encryptJSION(dataToSend).toString() }
        : null,
      params: paramsData
        ? { payload: encryptJSION(paramsData).toString() }
        : null,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return error.response;
    // if (error.response.status === 440) {
    //   window.location.href = "/";
    // }
    // if (error.response.status === 403) {
    // window.localStorage.removeItem("token");
    // window.location.href = "/";
    // }
    // return false;
  }
};
